import {Component, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-terms-modal',
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss']
})
export class TermsModalComponent {

  @Output() agree = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() {
  }
}
