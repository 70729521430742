import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: '[sa-nav]',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  iconPrefix = 'fal';
  pagename = 'dashboard';

  constructor() {
  }

  ngOnInit(): void {
  }

  closeMenu(): void {
    $('body').removeClass('mobile-nav-on');
  }

}
