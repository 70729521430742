
<li routerLinkActive="active">
<a routerLink="/dashboard" title="Blank Project" (click)="closeMenu()" data-filter-tags="blank page" >
  <i class="{{iconPrefix}} fa-analytics"></i>
  <span class="nav-link-text" data-i18n="nav.dashboard">{{'Dashboard' | i18next}}</span>
</a>
</li>
<li routerLinkActive="active">
<a routerLink="/devices" title="Blank Project" (click)="closeMenu()" data-filter-tags="Device page">
  <i class="{{iconPrefix}} fa-air-conditioner"></i>
  <span class="nav-link-text" data-i18n="nav.dashboard">{{'Devices' | i18next}}</span>
</a>
</li>
<li routerLinkActive="active">
<a routerLink="/control" title="Blank Project" (click)="closeMenu()" data-filter-tags="Control page">
  <i class="{{iconPrefix}} fa-house-signal"></i>
  <span class="nav-link-text" data-i18n="nav.dashboard">{{'Control' | i18next}}</span>
</a>
</li>
<li routerLinkActive="active">
<a routerLink="/schedule" title="Blank Project" (click)="closeMenu()" data-filter-tags="Schedule page">
  <i class="{{iconPrefix}} fa-history"></i>
  <span class="nav-link-text" data-i18n="nav.schedule">{{'Scenarios' | i18next}}</span>
</a>
</li>
