<div class="modal-header">
  <h1>Terms of use</h1>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close.emit(true)">
    &times;
  </button>
</div>
<div class="modal-body custom-scroll terms-body">

  <div id="left">
    <p><strong>
      Effective date:</strong> February 23, 2021</p>
    <p>Please read these Terms and Conditions (&quot;<strong>Terms</strong>&quot;, &quot;<strong>Terms and Conditions</strong>&quot;) carefully before
      using the Prana website (the &quot;<strong>Service</strong>&quot;) operated by Prana Platinum LLC (&quot;<strong>us</strong>&quot;, &quot;<strong>we</strong>&quot;,
      or &quot;<strong>our</strong>&quot;).</p>
    <p>Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms.
      These Terms apply to all visitors, users and others who wish to access or use the Service.</p>
    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms
      then you do not have permission to access the Service.</p>
    <h2>Services</h2>
    <p>By using our Services&nbsp; you have no any reason to worry about the air condition indoors. We will provide you
      with all necessary information concerning your recuperator work and its indexes as for:</p>
    <ul>
      <li>Inside and outside Temperature rate;
      </li>
      <li>Humidity rate;
      </li>
      <li>CO2 content.
      </li>
    </ul>
    <p>As well as functional characteristics of your devices such as:</p>
    <ul>
      <li>motor speed&nbsp; &nbsp;
      </li>
      <li>recuperator saving and
      </li>
      <li>power consumption.
      </li>
    </ul>
    <p>You will be provided with a general PDF or Excel report containing all the data mentioned above, that could be
      downloaded on your device.</p>
    <h2>Your obligations &nbsp;</h2>
    <p>You shall not, directly or indirectly:</p>
    <ul>
      <li>reverse engineer, decompile, disassemble or otherwise attempt to discover the source code, object code or
        underlying structure, ideas, know-how or algorithms relevant to the Services or any software, documentation or
        data related to the Services (&ldquo;<strong>Software</strong>&rdquo;);
      </li>
      <li>modify, translate, or create derivative works based on the Services or any Software.
      </li>
      <li>access all or any part of the Services or the Software in order to build a product or service which competes
        with the Services;
      </li>
      <li>use the Services to provide services to third parties;
      </li>
      <li>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit,
        or otherwise make the Services available to any third party except the employees or agents of the Customer;
      </li>
      <li>use the Services in a manner that is illegal or causes damage or injury to any person or property;
      </li>
      <li>access, store, distribute or use during the course of its use of the Services any malware or any material that
        is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically
        offensive; facilitates illegal activity; depicts sexually explicit images; promotes unlawful violence; is
        discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or
      </li>
      <li>attempt to obtain, or assist third parties in obtaining, access to the Services.
      </li>
    </ul>
    <h2>Communications &nbsp;</h2>
    <p>By creating an account on our service, you agree to subscribe to newsletters or marketing materials and other
      information we may send. However, you may opt out of receiving any, or all, of these communications from us by
      following the unsubscribe link or instructions provided in any email we send.</p>
    <h2>Copyright Policy &nbsp;</h2>
    <p>We respect the intellectual property rights of others. It is our policy to respond to any claim that content
      posted on the Service infringes on the copyright or other intellectual property rights (&quot;<strong>Infringement</strong>&quot;)
      of any person or entity.</p>
    <p>If you are a copyright owner, or authorized on behalf of one, and you believe that the copyrighted work has been
      copied in a way that constitutes copyright infringement, please submit your claim via email to, with the subject
      line: &quot;Copyright Infringement&quot; and include in your claim a detailed description of the alleged
      Infringement. You may be held accountable for damages (including costs and attorneys&#39; fees) for
      misrepresentation or bad-faith claims on the infringement of any Content found on and/or through the Service on
      your copyright.</p>
    <h2>Intellectual Property &nbsp;</h2>
    <p>The Service and its original content (excluding Content provided by users), features and functionality are and
      will remain the exclusive property of Prana Platinum LLC and its licensors. The Service is protected by copyright,
      trademark, and other laws around the world. Our trademarks and trade dress may not be used in connection with any
      product or service without our prior written consent</p>
    <h2>Links To Other Websites &nbsp;</h2>
    <p>Our Service may contain links to third party websites or services that are not owned or controlled by us.</p>
    <p>We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third
      party websites or services. We do not warrant the offerings of these entities/individuals or their websites.</p>
    <p>You acknowledge and agree that we are not responsible or liable, directly or indirectly, for any damage or loss
      caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services
      available on or through any such third party websites or services.</p>
    <p>We strongly advise you to read the terms and conditions and privacy policies of any third party websites or
      services that you visit.</p>
    <h2>Termination &nbsp;</h2>
    <p>We reserve the right to suspend or terminate your access to Service in case:</p>
    <ul>
      <li>Your actions do not match the provisions of these Terms or other policies;
      </li>
      <li>Your actions cause harm to Service.
      </li>
    </ul>
    <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including,
      without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.</p>
    <h2>Indemnification &nbsp;</h2>
    <p>You agree to defend, indemnify and hold us harmless and our licensee and licensors, and their employees,
      contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses,
      liabilities, costs or debt, and expenses (including but not limited to attorney&#39;s fees), resulting from or
      arising out of a) your use and access of the Service, b) a breach of these Terms.</p>
    <h2>Limitation Of Liability &nbsp;</h2>
    <p>In no event shall Prana Platinum LLC, nor its directors, employees, partners, agents, suppliers, or affiliates, be
      liable for any indirect, incidental, special, consequential or punitive damages, including without limitation,
      loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
      inability to access or use the Service; (ii) any conduct or content of any third party on the Service; (iii) any
      content obtained from the Service; and (iv) unauthorized access, use or alteration of your transmissions or
      content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether we
      have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have
      failed of its essential purpose.</p>
    <p>Except for the issues related to harm to life, health, physical property of users, the total liability of the
      company will not exceed those amounts paid by the user for the services provision.</p>
    <h2>Disclaimer &nbsp;</h2>
    <p>Your use of the Service is at your sole risk. The Service is provided on an &quot;AS IS&quot; and &quot;AS
      AVAILABLE&quot; basis. The Service is provided without warranties of any kind, whether express or implied,
      including, but not limited to, implied warranties of merchantability, fitness for a particular purpose,
      non-infringement or course of performance.</p>
    <p>Prana Platinum LLC, subsidiaries, affiliates, and its licensors do not warrant that a) the Service will function
      uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected;
      c) the Service is free of viruses or other harmful components; or d) the results of using the Service will meet
      your requirements.</p>
    <h2>Exclusions &nbsp;</h2>
    <p>Some jurisdictions do not allow the exclusion of certain warranties or the exclusion or limitation of liability
      for consequential or incidental damages, so the limitations above may not apply to you.</p>
    <h2>Miscellaneous&nbsp;</h2>
    <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.&nbsp;</p>
    <p>If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of
      these Terms will remain in effect.&nbsp;</p>
    <p>These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior
      agreements we might have had between us regarding the Service.</p>
    <h2>Changes &nbsp;</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to
      access or use our Service after any revisions become effective, you agree to be bound by the revised terms. If you
      do not agree to the new terms, you are no longer authorized to use the Service.</p>
    <h2>Contact Us &nbsp;</h2>
    <p>You may contact us regarding the Service or these Terms at:&nbsp;</p>
    <p>Limited Liability Company Prana Platinum (Prana Platinum LLC), Dudayeva Street, House 19, apartment 1, Lviv city,
      Halytskyi district, 79005, Ukraine, <a href="mailto:ai@prana.org.ua">ai@prana.org.ua</a>.</p>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="close.emit(true)">
    {{ 'Close' | i18next }}
  </button>
  <!--button type="button" class="btn btn-primary" id="i-agree" (click)="agree.emit(true)">
    <i class="fa fa-check"></i> I Agree
  </button>

  <button type="button" class="btn btn-danger pull-left" id="print">
    <i class="fa fa-print"></i> Print
  </button-->
</div>
