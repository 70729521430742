import {Component, OnInit, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-privacy-modal',
  templateUrl: './privacy-modal.component.html',
  styleUrls: ['./privacy-modal.component.scss']
})
export class PrivacyModalComponent {

  @Output() agree = new EventEmitter();
  @Output() close = new EventEmitter();

  constructor() {
  }
}
