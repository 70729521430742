import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {config} from '@app/core/smartadmin.config';
import {AuthService} from '@app/core/services/auth.service';


@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {

  public auth: AuthService;

  constructor(
    private injector: Injector,
  ) {
    this.auth = this.injector.get(AuthService);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    console.log('request.url', request.url);
    if (request.url.search(config.API_URL) === 0) {

      console.log('intercept add token');
      // attach token
      return this.handleApiRequest(request, next);
    } else {
      return next.handle(request);
    }
  }


  handleApiRequest(request, next): Observable<HttpEvent<any>> {
    const token = this.auth.getToken();
    request = token
      ? request.clone({
        setHeaders: {
          'X-Authorization': `Bearer ${token}`
        }
      })
      : request;

    const handler = next.handle(request).pipe(
      catchError((error, caught) => {
        if (error.status === 401 || error.status === 403) {

          this.auth.logout();
          return throwError(error);
        } else {
          return throwError(error);
        }
      })
    );

    return handler;
  }
}
