<div class="d-flex align-items-center flex-1 text-muted">
  <span class="hidden-md-down fw-700">{{'COPYRIGHT' | i18next }}</span>
</div>
<div>
  <ul class="list-table m-0">
    <li> </li>
    <li><a href="#" class="text-secondary" (click)="openModal($event, termsModal)">{{'Terms of Use' | i18next}}</a></li>
    <li class="pl-3"><a href="#" class="text-secondary" (click)="openModal($event, privacyModal)">{{'Privacy Policy' | i18next}}</a></li>
    <!--<li class="pl-3"><a href="info_app_docs.html" class="text-secondary fw-700">Documentation</a></li>-->
    <!--<li class="pl-3 fs-xl"><a href="https://wrapbootstrap.com/user/MyOrange" class="text-secondary" target="_blank"><i class="{{iconPrefix}} fa-question-circle" aria-hidden="true"></i></a></li>-->
  </ul>
</div>

<ng-template #termsModal>
  <app-terms-modal (close)="onModalClose()"></app-terms-modal>
</ng-template>

<ng-template #privacyModal>
  <app-privacy-modal (close)="onModalClose()"></app-privacy-modal>
</ng-template>
