import {Component, OnInit, TemplateRef} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal/bs-modal-ref.service';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: '[sa-footer]',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  iconPrefix = 'fal';

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit(): void {
  }

  openModal(event, template: TemplateRef<any>): void {
    event.preventDefault();
    this.bsModalRef = this.modalService.show(
      template,
      Object.assign({}, {class: 'gray modal-lg'})
    );
  }

  onModalClose(): void {
    this.bsModalRef.hide();
  }

}
