import {NgModule} from '@angular/core';
import {CommonModule, registerLocaleData} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {HeaderModule} from './header/header.module';
import {FooterComponent} from './footer/footer.component';
import {NavigationModule} from './navigation/navigation.module';
import {RibbonComponent} from './ribbon/ribbon.component';
import {ShortcutModalComponent} from './shortcut-modal/shortcut-modal.component';
import {ToggleActiveDirective} from '../utils/toggle-active.directive';
import {LayoutSwitcherComponent} from './layout-switcher.component';
import {MainLayoutComponent} from './app-layouts/main-layout.component';
import {EmptyLayoutComponent} from './app-layouts/empty-layout.component';
import {RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './app-layouts/auth-layout.component';

import {RouteBreadcrumbsComponent} from './ribbon/route-breadcrumbs.component';
import {UtilsModule} from '../utils/utils.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {LeftPanelComponent} from './left-panel/left-panel.component';
import {ContentOverlayComponent} from './content-overlay/content-overlay.component';
import {ColorProfileReferenceComponent} from './color-profile-reference/color-profile-reference.component';
import {LogoComponent} from './logo/logo.component';
import {NavFooterComponent} from './nav-footer/nav-footer.component';
import {NavInfoCardComponent} from './nav-info-card/nav-info-card.component';
import {NavComponent} from './nav/nav.component';
import {NavFilterMsgComponent} from './nav-filter-msg/nav-filter-msg.component';
import {NavFilterComponent} from './nav-filter/nav-filter.component';
import localeRu from '@angular/common/locales/ru';
import {I18NextModule} from 'angular-i18next';
import {PrivacyModalComponent} from '@shared/layout/privacy-modal/privacy-modal.component';
import {TermsModalComponent} from '@shared/layout/terms-modal/terms-modal.component';

registerLocaleData(localeRu, 'ru');

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    NavigationModule,
    FormsModule,
    RouterModule,
    UtilsModule,
    TooltipModule,
    BsDropdownModule,
    I18NextModule
  ],
  declarations: [
    FooterComponent,
    RibbonComponent,
    ShortcutModalComponent,
    LayoutSwitcherComponent,
    MainLayoutComponent,
    EmptyLayoutComponent,
    AuthLayoutComponent,
    RouteBreadcrumbsComponent,
    LeftPanelComponent,
    ContentOverlayComponent,
    ColorProfileReferenceComponent,
    LogoComponent,
    NavFooterComponent,
    NavInfoCardComponent,
    NavComponent,
    NavFilterMsgComponent,
    NavFilterComponent,
    PrivacyModalComponent,
    TermsModalComponent
  ],
  exports: [
    HeaderModule,
    NavigationModule,
    FooterComponent,
    RibbonComponent,
    ShortcutModalComponent,
    LayoutSwitcherComponent,
    PrivacyModalComponent,
    TermsModalComponent
  ]
})
export class SmartadminLayoutModule {

}
