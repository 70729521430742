import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {config} from '@app/core/smartadmin.config';
import {Observable, of} from 'rxjs';
import {delay, map, catchError, tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient) {
  }

  public get(url, params = null): Observable<any> {
    return this.http.get(config.API_URL + url, {params})
      .pipe(
        delay(100),
        // map((result: any) => (result.data || result)),
        catchError(this.handleError)
      );
  }

  public post(url, body = {}): Observable<any> {
    console.log('this.http.post', config.API_URL + url, body);
    return this.http.post(config.API_URL + url, body)
      .pipe(
        delay(100),
        tap((result: any) => console.log(result)),
        // map((result: any) => (result.data || result)),
        catchError(this.handleError)
      );
  }

  public delete(url, params = {}): Observable<any> {
    console.log('this.http.delete', config.API_URL + url, params);
    return this.http.delete(config.API_URL + url, {params})
      .pipe(
        delay(100),
        catchError(this.handleError)
      );
  }
  private handleError(error: any): Observable<string> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.error) ? error.error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    throw errMsg;
  }

}


