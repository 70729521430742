import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {Profile} from '@shared/models/profile.model';
import {Store} from '@ngrx/store';
import * as fromProfile from '@app/core/store/profile';
import {I18NEXT_SERVICE, ITranslationService} from 'angular-i18next';

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent implements OnInit {
  language = 'en';
  languages: string[] = ['en', 'ru', 'ua'];
  profile$: Observable<Profile>;

  iconPrefix = 'fal';

  constructor(
      @Inject(I18NEXT_SERVICE) private i18NextService: ITranslationService,
      private store$: Store<fromProfile.ProfileState>
  ) {
  }

  ngOnInit(): void {
    this.profile$ = this.store$.select(fromProfile.getProfileModel);
    this.i18NextService.events.initialized.subscribe((e) => {
      if (e) {
        this.language = this.i18NextService.language;
      }
    });
  }

  changeLanguage(lang: string): void {
    if (lang !== this.i18NextService.language) {
      this.i18NextService.changeLanguage(lang).then(x => {
        document.location.reload();
      });
    }
  }
}
