import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthLayoutComponent} from './shared/layout/app-layouts/auth-layout.component';
import {MainLayoutComponent} from './shared/layout/app-layouts/main-layout.component';
import {MetaGuard} from '@ngx-meta/core';
import {AuthGuard} from '@app/core/guards/auth.guard';
import {EmptyLayoutComponent} from '@shared/layout/app-layouts/empty-layout.component';
import {UnAuthGuard} from '@app/core/guards/un-auth.guard';
import {LogoutGuard} from '@app/core/guards/logout.guard';

const routes: Routes = [
  {path: '', redirectTo: 'auth', pathMatch: 'full'},
  {
    path: '',
    component: MainLayoutComponent,
    canActivateChild: [
      MetaGuard,
      AuthGuard
    ],
    children: [
      {path: 'dashboard', loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)},
      {path: 'devices', loadChildren: () => import('./features/devices/devices.module').then(m => m.DevicesModule)},
      {path: 'control', loadChildren: () => import('./features/control/control.module').then(m => m.ControlModule)},
      {path: 'schedule', loadChildren: () => import('./features/schedule/schedule.module').then(m => m.ScheduleModule)},
      {path: 'profile', loadChildren: () => import('./features/profile/profile.module').then(m => m.ProfileModule)},
    ],
  },
  {
    path: 'auth/logout',
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard],
    canLoad: [LogoutGuard]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule),
    canLoad: [UnAuthGuard],
  },
  {
    path: '',
    component: EmptyLayoutComponent,
    canActivateChild: [MetaGuard],
    children: [{path: '**', loadChildren: () => import('./features/not-found/not-found.module').then(m => m.NotFoundModule)}],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
     // {enableTracing: true}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
