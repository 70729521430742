<div class="modal-header">
  <h1>Privacy Policy</h1>
  <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="close.emit(true)">
    &times;
  </button>
</div>
<div class="modal-body custom-scroll terms-body">

  <div id="left">
    <p><strong>Effective date:</strong> February 23, 2021</p>
    <h2>Introduction</h2>
    <p>Prana Platinum LLC (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates the Prana website (&ldquo;<strong>Service</strong>&rdquo;).
    </p>
    <p>Our Privacy Policy explains how we collect, safeguard and disclose information that results from your use of our
      Service.</p>
    <p>By using Service, you agree to the collection and use of information in accordance with this policy. Unless
      otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our
      Terms and Conditions.</p>
    <p>Our Terms and Conditions (&ldquo;<strong>Terms</strong>&rdquo;) govern all use of our Service and together with
      the Privacy Policy constitutes your agreement with us (&ldquo;<strong>Agreement</strong>&rdquo;).</p>

    <h2>Information Collection and Use</h2>
    <p>We collect several types of information for various purposes to provide and improve our Service to you.</p>
    <h3>Types of Data Collected</h3>
    <h4>Personal Data</h4>
    <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can
      be used to contact or identify you (&ldquo;<strong>Personal Data</strong>&rdquo;).&nbsp;</p>
    <p>Personal Data may include:</p>
    <ul>
      <li>Email address</li>
      <li>First name and last name</li>
      <li>Phone number</li>
      <li>Cookies and Usage Data</li>
    </ul>
    <p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
      information that may be of interest to you. You may opt out of receiving any, or all, of these communications from
      us by following the unsubscribe link or by emailing at <a href="mailto:ai@prana.org.ua">ai@prana.org.ua</a></p>
    <h4>Usage Data</h4>
    <p>We may also collect information that your browser sends whenever you visit our Service or when you access Service
      by or through a mobile device (&ldquo;<strong>Usage Data</strong>&rdquo;).</p>
    <p>Such information may include</p>
    <ul>
      <li>computer&#39;s Internet Protocol address (e.g. IP address),</li>
      <li>browser type,</li>
      <li>browser version,</li>
      <li>type of device you are using</li>
      <li>the pages of our Service that you visit,</li>
      <li>time and date of your visit,</li>
      <li>time spent on those pages,</li>
      <li>unique device identifiers</li>
      <li>other diagnostic data.</li>
    </ul>
    <h4>Tracking Cookies Data</h4>
    <p>We use cookies and similar tracking technologies to track the activity on our Service.</p>
    <p>Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent
      to your browser from a website and stored on your device. Other tracking technologies are also used such as
      beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
    <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
      do not accept cookies, you may not be able to use some portions of our Service.</p>
    <p>Examples of Cookies we use:</p>
    <ul>
      <li><strong>Session Cookies:</strong> We use Session Cookies to operate our Service.</li>
      <li><strong>Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</li>
      <li><strong>Security Cookies:</strong> We use Security Cookies for security purposes.</li>
      <li><strong>Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you
        and your interests.
      </li>
    </ul>
    <h3>Use of Data</h3>
    <p>Data protection law means that we can only use your data for certain reasons and where we have a legal basis to
      do so. Here are the reasons for which we process your data:</p>
    <h4>Providing our Services</h4>
    <p>We use some of your information to provide our services.</p>
    <p><i>Legal basis: contract, legitimate interests</i></p>
    <h4>Improving our service</h4>
    <p>Testing features, interacting with feedback platforms and questionnaires, managing landing pages, heat mapping
      our site, traffic optimization and data analysis and research, including profiling and the use of machine learning
      and other techniques over your data and in some cases using third parties to do this.</p>
    <p><i>Legal basis: contract, legitimate interests</i></p>
    <h4>Customer support</h4>
    <p>Notifying you of any changes to our service, solving issues via live chat support, phone or email including any
      bug fixing.</p>
    <p><i>Legal basis : contract</i></p>
    <h4>Marketing purposes (with your consent)</h4>
    <p>Sending emails and messages about new features, products and services, and content.</p>
    <p><i>Legal basis: consent</i></p>
    <h3>Legal basis</h3>
    <h4>Consent</h4>
    <p>You have given clear consent for you to process your personal data for a specific purpose.</p>
    <p><strong>You can change your mind!</strong> If you have previously given consent to our processing your data you can freely
      withdraw such consent at any time. You can do this by emailing us at <a href="mailto:ai@prana.org.ua">ai@prana.org.ua</a></p>
    <p>If you do withdraw your consent, and if we do not have another legal basis for processing your information, then
      we will stop processing your personal data. If we do have another legal basis for processing your information,
      then we may continue to do so subject to your legal rights.</p>
    <h4>Contract</h4>
    <p>Processing your data is necessary for a contract you have with us, or because we have asked you to take specific
      steps before entering into that contract.</p>
    <h4>Legitimate interests</h4>
    <p>Processing your data is necessary for our legitimate interests or the legitimate interests of a third party,
      provided those interests are not outweighed by your rights and interests. These legitimate interests are:</p>
    <ul>
      <li>gaining insights from your behavior on our website or in our app</li>
      <li>delivering, developing and improving our service</li>
      <li>enabling us to enhance, customize or modify our services and communications</li>
      <li>determining whether marketing campaigns are effective</li>
      <li>enhancing data security</li>
    </ul>
    <p>In each case, these legitimate interests are only valid if they are not outweighed by your rights and
      interests</p>
    <h2>Retention of Data</h2>
    <p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy
      Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations
      (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and
      enforce our legal agreements and policies.</p>
    <p>Some data you can delete whenever you like, some data is deleted automatically, and some data we retain for
      longer periods of time when necessary. When you delete data, we follow a deletion policy to make sure that your
      data is safely and completely removed from our servers or retained only in anonymized form.</p>
    <p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter
      period, except when this data is used to strengthen the security or to improve the functionality of our Service,
      or we are legally obligated to retain this data for longer time periods.</p>
    <p>Extended time retention</p>
    <p>Sometimes business and legal requirements oblige us to retain certain information, for specific purposes, for an
      extended period of time. For example, when we processes a payment for you, or when you make a payment to us, we&rsquo;ll
      retain this data for longer periods of time as required for tax or accounting purposes. Reasons we might retain
      some data for longer periods of time include:</p>
    <ul>
      <li>Security, fraud &amp; abuse prevention</li>
      <li>Financial record-keeping</li>
      <li>Complying with legal or regulatory requirements</li>
      <li>Ensuring the continuity of our services</li>
      <li>Direct communications</li>
    </ul>
    <h2>Transfer of Data</h2>
    <p>Your information, including Personal Data, may be transferred to &ndash; and maintained on &ndash; computers
      located outside your state, province, country or other governmental jurisdiction where the data protection laws
      may differ from those of your jurisdiction.</p>
    <p>We will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance
      with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country
      unless there are adequate controls in place including the security of your data and other personal
      information.</p>
    <h2>Disclosure of Data</h2>
    <p>We may disclose personal information that we collect, or you provide:</p>
    <h4>Disclosure for Law Enforcement.</h4>
    <p>Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in
      response to valid requests by public authorities.</p>
    <h4>Business Transaction.</h4>
    <p>If we or our subsidiaries are involved in a merger, acquisition or asset sale, your Personal Data may be
      transferred.</p>
    <h4>&nbsp;Other cases. We may disclose your information also:</h4>
    <ul>
      <li>to our subsidiaries and affiliates;</li>
      <li>to contractors, service providers, and other third parties we use to support our business;</li>
      <li>to fulfill the purpose for which you provide it;</li>
      <li>with your consent in any other cases;</li>
      <li>if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
        Company, our customers, or others.
      </li>
    </ul>
    <h2>Security of Data</h2>
    <p>The security of your data is important to us but remember that no method of transmission over the Internet or
      method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your
      Personal Data, we cannot guarantee its absolute security.</p>
    <h4>Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h4>
    <p>If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data
      protection rights, covered by GDPR. &ndash; See more at <a href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" target="_blank">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a></p>
    <p>We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal
      Data.</p>
    <p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our
      systems, please email us at [support email].</p>
    <p>In certain circumstances, you have the following data protection rights:</p>
    <ul>
      <li>the right to access, update or to delete the information we have on you;</li>
      <li>the right of rectification. You have the right to have your information rectified if that information is
        inaccurate or incomplete;
      </li>
      <li>the right to object. You have the right to object to our processing of your Personal Data;</li>
      <li>the right of restriction. You have the right to request that we restrict the processing of your personal
        information;
      </li>
      <li>the right to data portability. You have the right to be provided with a copy of your Personal Data in a
        structured, machine-readable and commonly used format;
      </li>
      <li>the right to withdraw consent. You also have the right to withdraw your consent at any time when we rely on
        your consent to process your personal information;
      </li>
    </ul>
    <p>Please note that we may ask you to verify your identity before responding to such requests. Please note, we may
      not able to provide Service without some necessary data.</p>
    <p>You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data.
      For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
    <h2>Service Providers</h2>
    <p>We may employ third party companies and individuals to facilitate our Service (&ldquo;<strong>Service Providers</strong>&rdquo;),
      provide Service on our behalf, perform Service-related services or assist us in analyzing how our Service is
      used.</p>
    <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated
      not to disclose or use it for any other purpose.</p>
    <h3>Analytics</h3>
    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
    <h4>Google Analytics</h4>
    <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google
      uses the data collected to track and monitor the use of our Service. This data is shared with other Google
      services. Google may use the collected data to contextualize and personalize the ads of its own advertising
      network.</p>
    <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
      <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
    <p>We also encourage you to review the Google&#39;s policy for safeguarding your data:
      <a href="https://support.google.com/analytics/answer/6004245" target="_blank">https://support.google.com/analytics/answer/6004245</a>.</p>
    <h4>Behavioral Remarketing</h4>
    <p>We use remarketing services to advertise on third party websites to you after you visited our Service. We and our
      third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our Service.</p>
    <h4>Google Ads (AdWords)</h4>
    <p>Google Ads (AdWords) remarketing service is provided by Google Inc.</p>
    <p>You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by
      visiting the Google Ads Settings page: http://www.google.com/settings/ads</p>
    <p>Google also recommends installing the Google Analytics Opt-out Browser Add-on &ndash;
      <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a> &ndash; for your web browser. Google Analytics Opt-out Browser Add-on
      provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</p>
    <p>For more information on the privacy practices of Google, please visit the Google Privacy Terms web page:
      <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://policies.google.com/privacy?hl=en</a></p>
    <h2>Links to Other Sites</h2>
    <p>Our Service may contain links to other sites that are not operated by us. If you click a third party link, you
      will be directed to that third party&#39;s site. We strongly advise you to review the Privacy Policy of every site
      you visit.</p>
    <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third
      party sites or services.</p>
    <h2>Children&#39;s Privacy</h2>
    <p>Our Service does not address anyone under the age of 18 (&ldquo;<strong>Children</strong>&rdquo;).</p>
    <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a
      parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we
      become aware that we have collected Personal Data from children without verification of parental consent, we take
      steps to remove that information from our servers</p>
    <h2>Changes to This Privacy Policy</h2>
    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
      Policy on this page.</p>
    <p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective
      and update &ldquo;effective date&rdquo; at the top of this Privacy Policy.</p>
    <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
      effective when they are posted on this page.</p>
    <h2>Contact Us</h2>
    <p>You may contact us regarding the Service or these Terms at:</p>
    <p>Limited Liability Company Prana Platinum (Prana Platinum LLC), Dudayeva Street, House 19, apartment 1, Lviv city,
      Halytskyi district, 79005, Ukraine, <a href="mailto:ai@prana.org.ua">ai@prana.org.ua</a></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="close.emit(true)">
      {{ 'Close' | i18next }}
    </button>
    <!--button type="button" class="btn btn-primary" id="i-agree" (click)="agree.emit(true)">
      <i class="fa fa-check"></i> I Agree
    </button>

    <button type="button" class="btn btn-danger pull-left" id="print">
      <i class="fa fa-print"></i> Print
    </button-->
  </div>
