import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import {
  AuthDto,
  AuthResponseDto,
  RegisterFormDto,
  ResetPasswordByEmailDto,
  ResetPasswordDto,
  UserResponseDto
} from '@shared/models/auth.dto';
import {ApiService} from '@app/core/services/api.service';
// import {Store} from '@ngrx/store';
// import {UserService} from '@app/core/services/user.service';
// import {StorageService} from '@app/core/services/storage.service';

// import * as fromAuth from '../store/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public token: string = null;
  public refreshToken: string = null;

  public isLoggedIn = false;

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  constructor(
    private api: ApiService,
    //  private storage: StorageService,
    //  private userService: UserService
  ) {
    // this.storage.get('token').then(value => this.token = value);
    // this.storage.get('refreshToken').then(value => this.refreshToken = value);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  login(data: AuthDto): Observable<AuthResponseDto> {
    console.log('AuthService.login', data);
    return this.api.post('/auth/login', data);
  }

  register(data: RegisterFormDto): Observable<any> {
    console.log('AuthService.register', data);
    return this.api.post('/noauth/signup', data);
  }

  confirmEmail(emailCode: string): Observable<any> {
    console.log('AuthService.confirmEmail', emailCode);
    return this.api.post('/noauth/activateByEmailCode?emailCode=' + emailCode, {});
  }

  resetPasswordByEmail(data: ResetPasswordByEmailDto): Observable<any> {
    console.log('resetPasswordByEmail', data);
    return this.api.post('/noauth/resetPasswordByEmail', data);
  }

  resetPassword(data: ResetPasswordDto): Observable<any> {
    console.log('resetPassword', data);
    return this.api.post('/noauth/resetPassword', data);
  }

  getUser(): Observable<UserResponseDto> {
    return this.api.get('/auth/user');
  }

  saveUser(data: any): Observable<any> {
    return this.api.post('/user?sendActivationMail=false', data);
  }

  changePassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.api.post('/auth/changePassword', {currentPassword, newPassword});
  }


  public clearUserInfo(): void {
    // this.storage.remove('email');
    // this.storage.remove('name');
    // this.storage.remove('userId');
    // this.storage.remove('customerId');
    // this.storage.remove('lastDeviceIdForDashboard');
  }

  logout(): Observable<boolean> {
    return of(false)
      .pipe(
        tap(val => {

          console.log('logout');
          // this.storage.remove('token');
          // this.storage.remove('refreshToken');

          this.isLoggedIn = false;
//          this.userService.clearUserInfo();
        })
      );
  }
}
