import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiService} from '@app/core/services/api.service';
import * as moment from 'moment';
import {Devices, DeviceTelemetry} from '@shared/models/device.model';
import {concatMap, map, mapTo, reduce} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ThingsboardApiService {

  data = [];
  timeFormat = 'DD/MM/YYYY HH:mm:ss';

  logoPng = new Image(); // Создает новый элемент изображения
  legendPNG = new Image(); // Создает новый элемент изображения

  deviceId = '';

  constructor(
    private api: ApiService,
  ) {
    this.logoPng.src = '/img/logo.png'; // Устанавливает путь
    this.legendPNG.src = '/img/legend_motors_chart.png'; // Устанавливает путь

  }

  getDeviceTelemetry(keys, deviceId) {
    const keysString = keys.join(',');
    const params = {
      keys: keysString,
      // startTs: startTimestamp,
      // endTs: endTimestamp,
      // agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/timeseries`, params);
  }

  getDeviceTelemetryControl(keys, deviceId) {
    const keysString = keys.join(',');
    const params = {
      keys: keysString,
      // startTs: startTimestamp,
      // endTs: endTimestamp,
      // agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/attributes`, params).pipe(
      concatMap(data => {
        return of(data.reduce((ret, item: any) => ({...ret, [item.key]: item.value}), {}));
      })
    );
  }

  getDeviceTelemetries(startTimestamp, endTimestamp, agg, keys, deviceId, interval): Observable<DeviceTelemetry> {
    const keysString = keys.join(',');
    const params = {
      interval: Math.round((endTimestamp - startTimestamp) / interval),
      limit: 700,
      keys: keysString,
      startTs: startTimestamp,
      endTs: endTimestamp,
      agg
    };

    return this.api.get(`/plugins/telemetry/DEVICE/${deviceId}/values/timeseries`, params);
  }

  getUserDevices(customerId): Observable<Devices> {
    const params = {
      pageSize: 100,
      page: 0
    };
    return this.api.get(`/customer/${customerId}/devices`, params);
  }

  getUserDeviceGroupList(): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/entityGroups/DEVICE`, params);
  }

  apiGetEntityGroupsForEntity(entityType, entityId): Observable<any> {
    return this.api.get(`/entityGroups/${entityType}/${entityId}`);
  }

  apiGetEntityGroupById(entityGroupId): Observable<any> {
    return this.api.get(`/entityGroup/${entityGroupId}`);
  }

  apiDeleteEntityGroup(entityGroupId): Observable<any> {
    return this.api.delete(`/entityGroup/${entityGroupId}`);
  }

  apiGetDeviceGroupAttributes(entityId, entityType, scope): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/plugins/telemetry/${entityType}/${entityId}/values/attributes/${scope}`, params);
  }

  apiCreateDevicesGroup(nameOfGroup): Observable<string> {
    const params = {
      name: nameOfGroup,
      type: 'DEVICE'
    };

    return this.api.post(`/entityGroup`, params).pipe(
      map(groupInfo => groupInfo.id.id)
    );
  }

  apiUpdateDevicesGroup(params): Observable<string> {
    return this.api.post(`/entityGroup`, params);
  }

  apiAddDevicesToGroup(groupId, listOfDevices): Observable<any> {
    return this.api.post(`/entityGroup/${groupId}/addEntities`, listOfDevices);
  }

  apiDeleteDevicesFromGroup(groupId, listOfDevices): Observable<any> {
    return this.api.post(`/entityGroup/${groupId}/deleteEntities`, listOfDevices);
  }

  apiGetDevicesOfGroup(groupId): Observable<any> {
    const params = {
      pageSize: 100,
      page: 0,
      sortProperty: 'createdTime',
      sortOrder: 'DESC'
    };
    return this.api.get(`/entityGroup/${groupId}/devices`, params);
  }

  apiUpdateParametersToGroup(groupId, attributes): Observable<any> {
    return this.api.post(`/plugins/telemetry/ENTITY_GROUP/${groupId}/SERVER_SCOPE`, attributes);
  }

  apiUpdateParametersToDevice(deviceId, attributes): Observable<any> {
    return this.api.post(`/plugins/telemetry/DEVICE/${deviceId}/SERVER_SCOPE`, attributes);
  }

  apiUpdateDeviceScenarios(deviceId, scenarios): Observable<any> {
    const params = {
      method: 'setScenarios',
      params: {scenarios}
    };
    return this.api.post(`/plugins/rpc/oneway/${deviceId}/`, params);
  }

  apiDeleteDevice(deviceId): Observable<any> {
    return this.api.delete(`/device/${deviceId}`);
  }
}
